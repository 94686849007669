function httpsRequest() {
    window.onload = function() {
        const urlParams = new URLSearchParams(window.location.search);
        ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'].forEach((param) => {
            document.querySelectorAll(`input[name="${param}"]`).forEach((item) => {
                item.value = urlParams.get(param) || '';
            });
        });
        document.querySelectorAll('input[name="URL"]').forEach((item) => {
            item.value = window.location.href;
        });

        if(window.location.href.includes('?send-form')) {
            document.querySelector('.form-send').classList.toggle('--send');
        }

        const forms = document.querySelectorAll('form');
        forms.forEach(form => {
            form.action = `${form.action}?lbx_redirect_url=${window.location.href}?send-form`
        })
    }
}

httpsRequest()

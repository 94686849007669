document.addEventListener("DOMContentLoaded", function () {
    function checkNotice() {
      const notice = document.getElementById("notice");
      if (notice) {
        setTimeout(() => {
          notice.style.display = "none";
        }, 5000);
      } else {
        setTimeout(checkNotice, 100);
      }
    }

    checkNotice();
});

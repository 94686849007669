document.querySelectorAll('.btn-open-popup').forEach(button => {
    button.addEventListener('click', () => {
        document.querySelector('#free-consultation').classList.add('active');
        document.querySelector('.popup-bg').classList.add('active');
    });
});

document.querySelectorAll('.close, .popup-bg').forEach(element => {
    element.addEventListener('click', () => {
        document.querySelector('#free-consultation').classList.remove('active');
        document.querySelector('.popup-bg').classList.remove('active');
    });
});

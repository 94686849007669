document.addEventListener('DOMContentLoaded', function() {
    const accordionButtons = document.querySelectorAll('.accordion-btn');

    accordionButtons.forEach(button => {
      button.addEventListener('click', () => {
        const currentlyActive = document.querySelector('.accordion-content.active');
        const currentlyActiveButton = document.querySelector('.accordion-btn.active');

        // Закриття поточного активного акордеону
        if (currentlyActive && currentlyActive !== button.nextElementSibling) {
          currentlyActive.classList.remove('active');
          currentlyActiveButton.classList.remove('active');
        }

        // Відкриття або закриття вибраного акордеону
        const content = button.nextElementSibling;
        content.classList.toggle('active');
        button.classList.toggle('active');
      });
    });
});
